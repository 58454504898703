/* 
    We define our colours using a theme file like this to allow us to use both:
        - Tailwind CSS utility classes 
        - Custom Themes that can be applied by importing theme css files

    WARNING: 
    
    To ensure TailwindCSS transparency utility classes work (i.e bg-primary/50) 
     we MUST define colours using HSL or RGB values. We have chosen HSL. 
    
    DO NOT USE HEX VALUES.
*/
:root {
    /* Primary Colors */
    --primary-light-color: 234, 245, 254; /* #EAF5FE */
    --primary-baselight-color: 187, 222, 250; /* #BBDEFA */
    --primary-default-color: 0, 118, 214; /* #0076D6 */
    --primary-basedark-color: 12, 99, 170; /* #0C63AA */
    --primary-dark-color: 11, 71, 120; /* #0B4778 */
    --primary-darker-color: 3, 33, 59; /* #03213B */

    /* Secondary Colors */
    --secondary-light-color: 255, 255, 204; /* #FFFFCC */
    --secondary-baselight-color: 255, 233, 116; /* #FFE974 */
    --secondary-default-color: 255, 204, 0; /* #FFCC00 */
    --secondary-basedark-color: 193, 155, 0; /* #C19B00 */
    --secondary-dark-color: 142, 114, 0; /* #8E7200 */
    --secondary-darker-color: 82, 66, 0; /* #524200 */

    /* Tertiary Colors */
    --tertiary-light-color: 234, 220, 252; /* #EADCFC */
    --tertiary-baselight-color: 193, 149, 247; /* #C195F7 */
    --tertiary-default-color: 151, 79, 242; /* #974FF2 */
    --tertiary-basedark-color: 123, 64, 198; /* #7B40C6 */
    --tertiary-dark-color: 95, 48, 154; /* #5F309A */
    --tertiary-darker-color: 53, 25, 89; /* #351959 */

    /* Success Colors */
    --success-light-color: 207, 248, 195; /* #CFF8C3 */
    --success-baselight-color: 137, 203, 116; /* #89CB74 */
    --success-default-color: 58, 169, 23; /* #3AA917 */
    --success-basedark-color: 43, 132, 15; /* #2B840F */
    --success-dark-color: 35, 101, 14; /* #23650E */
    --success-darker-color: 23, 68, 9; /* #174409 */

    /* Warning Colors */
    --warning-light-color: 255, 237, 204; /* #FFEDCC */
    --warning-baselight-color: 255, 201, 102; /* #FFC966 */
    --warning-default-color: 255, 165, 0; /* #FFA500 */
    --warning-basedark-color: 227, 147, 0; /* #E39300 */
    --warning-dark-color: 153, 99, 0; /* #996300 */
    --warning-darker-color: 102, 66, 0; /* #664200 */

    /* Error Colors */
    --error-lighter-color: 255, 243, 242; /* #FFF3F2 */
    --error-light-color: 255, 209, 205; /* #FFD1CD */
    --error-baselight-color: 255, 134, 121; /* #FF8679 */
    --error-default-color: 255, 53, 32; /* #FF3520 */
    --error-basedark-color: 185, 17, 0; /* #B91100 */
    --error-dark-color: 111, 10, 0; /* #6F0A00 */
    --error-darker-color: 77, 13, 6; /* #4D0D06 */

    /* Neutral Colors */
    --neutral-lightest-color: 249, 250, 251; /* #F9FAFB */
    --neutral-lighter-color: 243, 244, 246; /* #F3F4F6 */
    --neutral-light-color: 229, 231, 235; /* #E5E7EB */
    --neutral-baselight-color: 209, 213, 219; /* #D1D5DB */
    --neutral-default-color: 126, 135, 149; /* #7E8795 */
    --neutral-basedark-color: 75, 85, 99; /* #4B5563 */
    --neutral-dark-color: 44, 53, 67; /* #2C3543 */
    --neutral-darker-color: 31, 41, 55; /* #1F2937 */
    --neutral-darkest-color: 17, 24, 39; /* #111827 */

    /* Black & White */
    --black-color: 0, 0, 0; /* #000000 */
    --white-color: 255, 255, 255; /* #FFFFFF */
}
