#ui\/editorAutocompleteRoot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Bring above ModalRoot */
    z-index: 4;
}
#ui\/editorAutocompleteRoot:empty {
    display: none;
}
