@import "./ContextMenu/global.css";
@import "./EquationEditor/global.css";

/* 
  We are intentionally excluding the base import as:
  - it contains mostly css resets that will conflict with styled-components
  - we have our own custom reset.css file

  The line is left here to make it obvious that we are intentionally excluding it
*/
/* @tailwind base; */

/* Import tailwindcss utilities such as p-5 pt-5 hidden  etc */
@tailwind utilities;

/* Import base tailwind components */
@tailwind components;

@import "./defaultTheme.css";